import Vue from "vue";

/*TITLE*/
document.title = "Oxigen | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Oxigen";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Oxigen";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-vela-grey-596x596--20230126030140.jpg";
Vue.prototype.$image_kitchen = "cocina-matika-bone-596x596-clear-xtone-warm--20230126030154.jpg";
Vue.prototype.$image_bath1 = "banop-vela-grey-596x596-vela-grey-316x90-limit-vela-grey-316x90--20230126030106.jpg";
Vue.prototype.$image_bath2 = "banosec-vela-grey-596x596-vela-grey-316x90--20230126030143.jpg";
Vue.prototype.$image_room = "habp-vela-grey-596x596--20230126030122.jpg";
Vue.prototype.$image_facade = "porcelanosa-maria-montessori-fachada-v6--20230126030110.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-vela-grey-596x596--20230126030140.jpg",
  },
  {
    src: "salon-matika-bone-596x596--20230126030147.jpg",
  },
  {
    src: "salon-forest-arce-143x90--20230126030153.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-matika-bone-596x596-clear-xtone-warm--20230126030154.jpg",
  },
  {
    src: "cocina-forest-arce-143x90-cool-krion-blanco--20230126030101.jpg",
  },
  {
    src: "cocina-vela-grey-596x596-nogal-moon-xtone-dove--20230126030110.jpg",
  },
  {
    src: "cocina-matika-bone-596x596-eucalytus-blond-xtone-white--20230126030119.jpg",
  },
  {
    src: "cocina-forest-arce-143x90-blanco-nubol-krion-blanco--20230126030129.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-vela-grey-596x596-vela-grey-316x90-limit-vela-grey-316x90--20230126030106.jpg",
  },
  {
    src: "banop-matika-bone-596x596-matika-bone-316x90-block-matika-bone-316x90--20230126030112.jpg",
  },
  {
    src: "banop-forest-arce-143x90-vela-grey-316x90-limit-vela-grey-316x90--20230126030119.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-vela-grey-596x596-vela-grey-316x90--20230126030143.jpg",
  },
  {
    src: "banosec-matika-bone-596x596-matika-bone-316x90--20230126030149.jpg",
  },
  {
    src: "banosec-forest-arce-143x90-matika-bone-316x90--20230126030158.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "habp-vela-grey-596x596--20230126030122.jpg",
  },
  {
    src: "habp-matika-bone-596x596--20230126030129.jpg",
  },
  {
    src: "habp-forest-arce-143x90--20230126030135.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "porcelanosa-maria-montessori-fachada-v6--20230126030110.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/OXIGEN/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/805583967?h=92194005c5";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://sites.porcelanosa.com/spaces/tag/oxigen/";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=maria%20montsesori%2012%20lleida",
    text: "Maria Montsesori 12 Lleida",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=av%20blondel%2011%20lleida",
    text: "Av. Blondel 11, LLeida",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:973269100",
    text: "973269100",
  },
  {
    icon: "mdi-email",
    link: "mailto:lleida@finquesfarre.com",
    text: "lleida@finquesfarre.com",
  },
];
